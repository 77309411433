import React, {useState} from 'react';
import { useParams } from "react-router-dom";
import NavTwo from './components/NavBar';
import Footer from './components/Footer';
import configData from "../config.json";


const BusinessDeveloper = () => {
    let { id } = useParams();
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [backgroundImgUrl, setBackgroundImgUrl] = useState('');
    const [profimage, setProfImage] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [devStatus, setDevStatus] = useState('');
    const [referer, setReferer] = useState(id);


    const addDeveloper = async  () => {
        // e.preventDefault();
        setisBtnLoading(true);

        // return alert("message")

        const formData = new FormData()
        formData.append('profimage', profimage);
        formData.append('firstName', firstName);
        formData.append('lastName', lastName);
        formData.append('phone', phone);
        formData.append('address', address);
        formData.append('email', email);
        formData.append('devStatus', devStatus);

        
        return fetch(`${configData.SERVER_URL}/developer/addDeveloper`, {
            method: "post",
            headers: {
                Accept: "application/json",
            },
            body:formData
        })
        .then((response) => response.json())
        .then((responseJson) => {

            if(responseJson.status === "success"){

                setisBtnLoading(false);

                alert(responseJson.message);
                window.location.reload()
            }
            if (responseJson.status === "error") {
                setisBtnLoading(false);
                alert(responseJson.message);
            }
        })
        .catch((error) => {
            console.error(error);
        });

    }

    let profFunc = (data) => {
        console.log(data);
    }

    const handleImgChange = (e) =>{
    //    return console.log(e.targert.files)


        let reader = new FileReader();
        let file  = e.targert.files[0];
        // console.log("file");
        reader.onloadend =  () =>{
            console.log(reader.result);
          setBackgroundImgUrl(reader.result);
        }
        reader.readAsDataURL(file);
        console.log(e.target.files[0]);
      }
      const addBgImg = () =>{
        const img = document.getElementById("event-banner");
        img.click();
      }
  


    return (
        <>
    
    <NavTwo/>

    <div class="container">
        <div class="row">
            <div class="col-md-3"></div>

            <div class="col-md-6 mt-5 mb-5">
                {/* Default form register */}
                <form  onSubmit={addDeveloper} method="POST" encType="multipart/form-data" id="submit" class="text-center border border-light p-5">
                        <p class="h4 mb-4">Register as a realtor</p>

                        <div class="form-row mb-4">
                            <div class="col-md-3"></div>
                            <div class="col-md-6">

        {backgroundImgUrl &&<img src={backgroundImgUrl} alt=''/>}
                                <img src={profimage ? profimage :'img/user.png'} class="img-fluid" id="profImg"/>
                            </div>
                            <div class="col-md-3"></div>
                            <div class="col-md-3"></div>
                            <div class="col-md-6 mt-2">                                        
          {/* <div>
            <div className="img-icon" onClick={() => addBgImg()}>
              <p>img src</p>
            </div>
            <p>Add your Image</p>
            <input type="file" hidden  id="event-banner" onChange={(e)=> handleImgChange(e)}/>
          </div> */}
     
                                <p>Choose File to Upload Picture</p>
                                {/* {profimage} */}
                                <input type="file" name="profimage" placeholder="choose image" onChange={(e)=> profFunc(e.target.files[0])}/>
                                
                            </div>
                            <div class="col-md-3"></div>
                        </div>

                        <div class="form-row mb-4">
                            <div class="col-md-6 mt-4">
                                {/* {/* First name */}
                                <input type="text" id="defaultRegisterFormFirstName" className="form-control" placeholder="First name" name="firstName" onChange={(e)=> setFirstName(e.target.value)}/>
                            </div>
                            <div class="col-md-6 mt-4">
                                {/* Last name */}
                                <input type="text" id="defaultRegisterFormLastName" className="form-control" placeholder="Last name" name="lastName" onChange={(e)=> setLastName(e.target.value)}/>
                            </div>
                        </div>

                        {/* E-mail */}
                        <input type="email" id="defaultRegisterFormEmail" className="form-control mb-4" placeholder="E-mail" name="email" onChange={(e)=> setEmail(e.target.value)}/>

                        {/* Phone number */}
                        <input type="tel" id="defaultRegisterPhonePassword" className="form-control" placeholder="Phone number" name="phone" onChange={(e)=> setPhone(e.target.value)}/>
                        <small id="defaultRegisterFormPhoneHelpBlock" className="form-text text-muted mb-4">
                        </small>
                        
                        <textarea type="text" id="defaultRegisterFormContact" className="form-control" placeholder="Contact Address" rows="2" name="contact" onChange={(e)=> setAddress(e.target.value)}></textarea>
                      
                      <div className="mt-4">
                        <span>Referer's ID</span>
                        <input type="text" id="defaultRegisterFormFirstName" className="form-control mt-2" placeholder="Referer ID" 
                        value={referer}
                        name="referer" onChange={(e)=> setReferer(e.target.value)}/>

                        <small id="defaultRegisterFormPasswordHelpBlock" className="form-text text-muted mb-4">
                        </small>
                      </div>

                        <div class="col-md-12">
                            <p>Do you have experience in Real Estate marketing?</p>
                            {/* Default inline 1*/}
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" className="custom-control-input" id="defaultInline1" name="BD" value="Yes" onChange={(e)=> setDevStatus(e.target.value)}/>
                                <label class="custom-control-label" for="defaultInline1">Yes</label>
                            </div>

                        {/* Default inline 2*/}
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="radio" className="custom-control-input" id="defaultInline2" name="BD" value="No"  onChange={(e)=> setDevStatus(e.target.value)}/>
                            <label className="custom-control-label" for="defaultInline2">No</label>
                        </div>
                        </div>
                        {/* Terms of service */}
                        <p className='mt-4'>By clicking
                            <em> Register</em> you agree to being a part of our team <br/>
                            <a href="" target="_blank"> Terms &amp; Conditions Apply</a>
                            </p>

                        {/* Sign up button */}
                        <button onClick={()=> addDeveloper()} className="btn btn-color my-4 btn-block waves-effect waves-light text-white"  type="submit" disabled={isBtnLoading}>     
                                {isBtnLoading ? (<>Please Wait...</>) : (<>Register</>)}
                        </button>
                    


                    </form>
                    {/* Default form register */}
                    
                    </div>
            
            <div class="col-md-3"></div>
            
        </div>
     </div>



    <Footer/>
            
        </>
    );
};

export default BusinessDeveloper;