import React  from 'react';
import styles from "./Style.module.css";
import Layout from '../../Layout';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
const EventGallery = () => {
	const owlResponsive = {
		0: {
			items: 1,
		},
		450: {
			items: 2,
		},
		600: {
			items: 2,
		},
		1000: {
			items: 2,
		}
	}

    return (
        <>
        <Layout>

            <section className={`${styles.detailPage}`}>
            <div className={styles.eventBox}>
                            <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s">CHOP life <br/>and tour 2021</h2> 
                            <div className={styles.lineDiv}>
                                <span className={styles.galleryLine}></span>
                                <h5 className={styles.galleryText}>Picture Gallery</h5>
                            </div>
                     </div>


            <OwlCarousel items={3} responsive={owlResponsive} stagePadding={"50"} margin={8} autoplay={false}  loop={false} nav={true} dots={false}>
                                    <div>
                                        <img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/1.jpeg"  id="myImg"/>
                                      </div>  
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/2.jpeg"  id="myImg"/>
                                      </div>  
                                    {/* <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/3.jpeg"  id="myImg"/>
                                      </div>   */}
                                    {/* <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/4.jpeg"  id="myImg"/>
                                      </div>   */}
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/5.jpeg"  id="myImg"/>
                                      </div> 
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/6.jpeg"  id="myImg"/>
                                      </div> 
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/7.jpeg"  id="myImg"/>
                                      </div> 
                                    {/* <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/8.jpeg"  id="myImg"/>
                                      </div>  */}
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/9.jpeg"  id="myImg"/>
                                      </div> 
                                    <div>
                                      	<img className="img wow fadeInRight" data-wow-duration="0.8s" src="/images/events/Chop/10.jpeg"  id="myImg"/>
                                      </div> 
                              </OwlCarousel> 


            {/*
                <div className={`${styles.galleryParent}`}>
                    <div className={`${styles.picture}`}>
                        <img src="images/77.jpeg" className={`${styles.eventImg} ${"d-inline-block align-top img-fluid"}`} data-wow-duration="1s" alt=""/> 
                    </div>
                    <div className={`${styles.picture}`}>
                        <p>hudbcudh</p>
                    </div>
                    <div className={`${styles.picture}`}>
                        <p>hudbcudh</p>
                    </div>

                </div> */}


            </section>



        </Layout>
            
        </>
    );
};

export default EventGallery;