import React from 'react';

import NavBar from './page/components/NavBar';
import Footer from './page/components/Footer';

// import {motion} from "framer-motion/dist/framer-motion"

const Layout = ({children}) => {


    
    return (

        
        
        <div className='layout-body'>

            <NavBar/>

            <div className='content'>

                            
                 {children}

             </div>

            <Footer/>

        </div>

             
            
    );
};

export default Layout;