import React, {useState}  from 'react';
import styles from "./Style.module.css";
import Layout from '../../Layout';
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {Modal, Row, Col, Accordion } from 'react-bootstrap';


const EventGallery = () => {

    const [modalShow, setModalShow] = useState(false);
    const [image, setImage] = useState("");

    const imgFunc = (data) => {
        setImage(data)
        setModalShow(true);
    }

	const owlResponsive = {
		0: {
			items: 1,
		},
		450: {
			items: 2,
		},
		600: {
			items: 2,
		},
		1000: {
			items: 2,
		}
	}

    return (
        <>
        <Layout>

            <section className={`${styles.detailPage}`}>
            <div className={styles.eventBox}>
                            <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s">Massive  <br/>Sales Submit</h2> 
                            <div className={`${styles.lineDiv} ${"wow fadeInUp"}`}  data-wow-duration="0.8s">
                                <span className={styles.galleryLine}></span>
                                <h5 className={styles.galleryText}>Picture Gallery</h5>
                            </div>
                     </div>


            <OwlCarousel items={3} responsive={owlResponsive} stagePadding={"50"} margin={8} autoplay={false}  loop={false} nav={true} dots={false}>
                                    <div onClick={()=> imgFunc("/images/events/MSS/1.jpeg")}>
                                        <img className="img" src="/images/events/MSS/1.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/2.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/2.jpeg"  id="myImg"/>
                                      </div>   
                                    <div onClick={()=> imgFunc("/images/events/MSS/4.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/4.jpeg"  id="myImg"/>
                                      </div>  
                                    <div onClick={()=> imgFunc("/images/events/MSS/5.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/5.jpeg"  id="myImg"/>
                                      </div>  
                                    <div onClick={()=> imgFunc("/images/events/MSS/6.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/6.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/7.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/7.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/8.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/8.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/9.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/9.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/10.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/10.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/11.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/11.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/12.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/12.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/13.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/13.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/14.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/14.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/15.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/15.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/16.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/16.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/17.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/17.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/18.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/18.jpeg"  id="myImg"/>
                                      </div> 
                                    <div onClick={()=> imgFunc("/images/events/MSS/19.jpeg")}>
                                      	<img className="img" src="/images/events/MSS/19.jpeg"  id="myImg"/>
                                      </div> 
                              </OwlCarousel> 


                              <Modal
      show={modalShow}
      onHide={() => setModalShow(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Body>

          <img className="img-fluid" data-wow-duration="0.8s" src={image}  id="myImg"/>
        
      </Modal.Body>
    </Modal>

     


            </section>



        </Layout>
            
        </>
    );
};

export default EventGallery;