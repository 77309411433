import React from 'react';
import styles from "./Style.module.css";
import { Link } from "react-router-dom";
import Layout from '../../Layout';
const Events = () => {
    return (
        <Layout>

            <section className={styles.sectionOne}>

                <div className={styles.boxOne}>
                    
                    <div className={styles.estateBoxOne}>
                            <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s">Catch the</h2> 
                            <h2 className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="0.8s">highlights of</h2> 
                            <h2  className={`${styles.headText} ${"home-header wow fadeInUp"}`}  data-wow-duration="1.2s">our events.</h2>
                     </div>

                 </div>
                <div className={styles.boxTwo}>
                    
                      <p className={`${styles.paragraphTitle} ${"wow fadeInUp"}`}  data-wow-duration="1.2s">We train and equip our team to operate as first class sales representative in an ever competitive digital world.</p>

                        <span className={`${styles.dot}`}></span>
                        <div className={`${styles.lineP}`}>
                            <div className={`${styles.line}`}></div>
                        </div>
                 </div>

             </section>

             <section className={styles.eventParent}>

                <div className={styles.events}>

                    <a href='/Massive-Sales-Submit' className={`${styles.event} ${'wow fadeInUp'}`} data-wow-duration="0.5s">
                        <img src="images/77.jpeg" className={`${styles.eventImg} ${"d-inline-block align-top img-fluid"}`} data-wow-duration="1s" alt=""/> 
                        <div className={`${styles.eventDetails}`}>
                           <p className={styles.eventTitle}>Massive Sales Submit</p>
                           <p className={styles.eventDate}>January 2022</p>
                        </div>
                    </a>
                    <a href='/Chop' className={`${styles.event} ${'wow fadeInUp'}`} data-wow-duration="0.8s">
                        <img src="/images/events/Chop/7.jpeg" className={`${styles.eventImg} ${"d-inline-block align-top img-fluid"}`} data-wow-duration="1s" alt=""/> 
                        <div className={`${styles.eventDetails}`}>
                           <p className={styles.eventTitle}>CHOP life and tour</p>
                           <p className={styles.eventDate}>January 2021</p>
                        </div>
                    </a>
                    {/* <div className={`${styles.event} ${'wow fadeInUp'}`} data-wow-duration="1s">
                        <img src="images/77.jpeg" className={`${styles.eventImg} ${"d-inline-block align-top img-fluid"}`} data-wow-duration="1s" alt=""/> 
                        <div className={`${styles.eventDetails}`}>
                           <p className={styles.eventTitle}>Massive Sales Submit</p>
                           <p className={styles.eventDate}>April 2023</p>
                        </div>

                    </div> */}
                  
                   

                  

                </div>

             </section>

            
        </Layout>
    );
};

export default Events;