import React, {useState, useEffect} from 'react';
import { Link } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

import Layout from '../Layout';
// import {motion} from "framer-motion/dist/framer-motion"

const Home = () => {
    const [pageLoading, setPageLoading] = useState(false);
    return (
        <>
   
        
        <Layout>

        <section className='sectionOne'>

           <div className='boxOne'>
                
                <div className='boxOneContent'>
                        <p className="safe wow fadeInDown"  data-wow-duration="0.8s">Safe & Guaranteed</p>
                        <h2 className="home-header wow fadeInUp"  data-wow-duration="0.8s">INVEST WITH</h2> 
                        <h2  className="home-header wow fadeInUp"  data-wow-duration="1.2s">ENTERO HOMES</h2>
                        <p className='paragraph-title wow fadeInUp'  data-wow-duration="1.8s">Your most secured and safest way to invest in real estate<br className="hide"/>  and get an investment return in the shortest time possible</p>

                        <Link to="/Estates-Layout" className="btn btn-primary btn-rounded wow fadeInUp"  data-wow-duration="2.2s" type="button">Start Now</Link>

                        <div className='highlightSec'>
                            <div className='mini'>
                                <h5 className="wow fadeInUp"  data-wow-duration="1s">7k +</h5>
                                <p className="wow fadeInUp"  data-wow-duration="1.2s">Happy Clients</p>
                            </div>
                            <div className='mini'>
                                <h5 className="wow fadeInUp"  data-wow-duration="1.5s">10k +</h5>
                                <p className="wow fadeInUp"  data-wow-duration="1.7s">Properties Sold</p>
                            </div>
                            <div className='mini'>
                                <h5 className="wow fadeInUp"  data-wow-duration="2s">15</h5>
                                <p className="wow fadeInUp"  data-wow-duration="2.2s">Estates/Layouts</p>
                            </div>

                        </div>

                </div>

                </div>
            <div className='boxTwo'>
                <LazyLoadImage 
                //  PlaceholderSrc={"images/preview2.png"}
                //  effect="blur"
                 src="images/2.jpeg" className="d-inline-block align-top sectionImg wow fadeIn"  data-wow-duration="3.5s" alt=""/>  
            </div>


        </section>


        <section className='sectionTwo'>
            <div className='secBoxOne'>
                <h4 className="wow fadeInUp"  data-wow-duration="1.2s">Our objectives is to provide<br/> properties with exceptional <br/>features.</h4>
            
                <p  className="wow fadeInUp"  data-wow-duration="1.5s">We are offering you more to choose from.<br/> With us, investment is stress free and hassle free.</p>
                <div className="keyPoints">
                    <div>
                        <span className="wow fadeInUp"  data-wow-duration="1.7s">NO AGENT FEE</span>
                        </div>
                    <div>
                        <span className="wow fadeInUp"  data-wow-duration="1.9s">NO BUSH ENTRY</span>
                     </div>
                    <div>
                        <span className="wow fadeInUp"  data-wow-duration="2.1s">FREE SURVEY</span>
                    </div>
                    <div>
                        <span className="wow fadeInUp"  data-wow-duration="2.3s">DEED OF CONVEYANCE</span>
                    </div>
                 </div>

               </div>

                <div className='secBoxTwo'>
                    <LazyLoadImage 
                         src="/images/preview1.png" 
                         PlaceholderSrc={"images/preview2.png"}
                         effect="blur"
                         className="img-fluid  objec-img d-inline-block align-top wow fadeIn"  
                         data-wow-duration="3.5s" alt=""/> 
                </div>


        </section>

        <section className='sectionThree'>
            <div className='sectionThreeContent'>
                    <h3 className="wow fadeInUp mb-4"  data-wow-duration="1s">Easy Steps to Buy<br/> Our Property</h3>

                <div className="stepsCon">
                    
                    <div className='steps wow fadeInUp' data-wow-duration="1.2s">
                        <div className='stepTitle'>
                            <span>1</span>
                            <h4>Choose a Property</h4>
                        </div>
                            <p>This is when a client indicates interest in any of our Estates. You can choose between an estate or a layout.</p>
                    </div>
                    <div className='steps wow fadeInUp' data-wow-duration="1.2s">
                        <div className='stepTitle'>
                            <span>2</span>
                            <h4>Contact Us</h4>
                        </div>
                            <p>Our sales team is always available to book you for a free site inspection and provide answers to all your questions. To get on a call with us today: +2347040005553 </p>
                    </div>
                    <div className='steps wow fadeInUp' data-wow-duration="1.2s">
                        <div className='stepTitle'>
                            <span>3</span>
                            <h4>Request a Visit</h4>
                         </div>
                            <p>Book for a free site inspection to see any of our properties. Inspections are usually on Wednesdays and Saturdays by 10am. However, we can take you any other day your availability permits. </p>
                    </div>
                    <div className='steps wow fadeInUp' data-wow-duration="1.2s">
                        <div className='stepTitle'>
                            <span>4</span>
                            <h4>Buy Property</h4>
                          </div>
                            <p>Pay for the property of your choice and get an instant allocation. Payments can be Outright or spread within 3,6 and 12 months.</p>
                    </div>

                 </div>
            </div>

        </section>


        <section className="sectionPromo">



     </section>


        </Layout>

    
            
        </>
    );
};

export default Home;