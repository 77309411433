import React from 'react';

import { Link } from "react-router-dom";
import Layout from '../Layout';


const Services = () => {
    return (
        <>

<Layout>


<section className="abtSecOne">
     <div className="subOne">
         <h3 className='wow fadeInUp' data-wow-duration="0.8s">We offer</h3>
         <h3 className='posOne wow fadeInUp' data-wow-duration="1s">premium quality</h3>
         <h3 className='posTwo wow fadeInUp' data-wow-duration="1.2s">services</h3>
     </div>
     <div className="subTwo">
             <div className="firstBox">
                <p className='paragraph-title wow fadeInUp' data-wow-duration="0.8s">We are company with sole interest in sales/purchase of properties, construction, human capital development and consultancy rapidly spreading its frontiers across Nigeria</p>
             </div>
     </div>

  </section>



  <section className="abtSecOne">

        <div className='secTwoDivOne'>
            <span></span>
        </div>
        <div className='servTwoDivTwo'>


                <div className='servCon mt-5'>
                    
                    <div className='serv wow fadeInUp' data-wow-duration="0.8s">
                            <div className='servFlex'>
                               <span>1</span>
                               <h4> Human Capacity Development</h4>
                            </div>
                            <p className=''>One of the integral part of Entero Homes Limited is our commitment to developing the capacity of our team members. We provide them with professional trainings and the necessary equipments making them fit for the market in order to yield the best results.</p>
                    </div>
                    <div className='serv wow fadeInUp' data-wow-duration="0.9s">
                            <div className='servFlex'>
                               <span>2</span>
                            <h4> Construction</h4>
                            </div>
                            <p>We don't only sell lands, we are creators of beautiful houses. For instance our Fountain Estate at Rukpukwu has all the finesse of a beautiful modern estate as we've been able to bring our clients' dream of a beautiful houses into a reality. With quality materials and classic engineers, we create a world of excellence when it comes to construction.</p>
                    </div>
                    
                    
                    <div className='serv wow fadeInUp' data-wow-duration="1s">
                            <div className='servFlex'>
                               <span>3</span> 
                               <h4> Sales/ Purchase of Properties</h4>
                            </div>
                            <p className=''>Every property Marketed by Entero Homes Limited are fully owned by us. We do due diligence during the acquisition stage, prepare the sites before we put them out for sales.</p>
                    </div>
                    <div className='serv wow fadeInUp' data-wow-duration="1.2s">
                            <div className='servFlex'>
                               <span>4</span> 
                            <h4> Consultancy</h4>
                            </div>
                            <p className=''>As a leading firm in the industry; we provide our clients with professional advises, services and guidance through the different stages of their investment with us.</p>
                    </div>

                </div>

            
        </div>


</section>






 
</Layout>

            
        </>
    );
};

export default Services;