import React, {useState, useEffect} from 'react';
import Layout from '../../Layout';
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import configData from "../../config.json";
import NumberFormat from 'react-number-format';
import { LazyLoadImage } from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';

const Estates = () => {
    const navigate = useNavigate();
    // const {state} = useLocation();
    // const { data } = state;

    const [estate, setEstate] = useState([]);
    const [all, setAll] = useState(true);
    const [available, setAvailable] = useState(false);
    const [isSoldOut, setisSoldOut]  = useState(false);
    const [isBtnLoading, setisBtnLoading] = useState(false);
    const [features, setFeatures] = useState('');
    const [pageContent, setPageContent] = useState('');




    const getEstates = () => {

        setisBtnLoading(true);
      return axios.get(`${configData.SERVER_URL}/estate/getEstate`).then((response) => {
          console.log(response);
          setEstate(response.data.data);
          setisBtnLoading(false);
        });
  
     }

     useEffect(() => {
        getEstates();
      }, []);



      const estDetails = (data) => {
        const url = data.name.replace(/ +/g, "");
        navigate(`/EstateDetails/${url}`, { state: { data: data} });
       }
     



    return (


        <Layout>



{isBtnLoading ? (<> 

<div className="col-md-12 d-flex justify-content-center pt-5 pb-5">
            <div class="spinner-border" role="status">
               
            </div>
 </div>

    </>) 

    : (<>

           <section className="abtSecOne">
                <div className="subOne">
                    <h3 className='wow fadeInUp' data-wow-duration="0.8s">Our Estates </h3>
                    <h3 className='posOne wow fadeInUp' data-wow-duration="1s">are designed for</h3>
                    <h3 className='posTwo wow fadeInUp' data-wow-duration="1.2s">luxury.</h3>
                </div>
                <div className="subTwo">

                        <div className="firstBox">
                           <p className='wow fadeInUp paragraph-title' data-wow-duration="0.8s">We are a fast growing real estate company rapidly spreading its frontiers across Nigeria, with sole interest in sales/purchase of properties, construction, human capital development and consultancy.</p>
                        </div>
                   <div className='option'>
                        <span className="wow fadeInUp"  data-wow-duration="1.8s"><i className="fa fa-check-circle pop-icons"></i> NO AGENT FEE</span>
                        <span className="wow fadeInUp"  data-wow-duration="1.8s"><i className="fa fa-check-circle pop-icons"></i> NO BUSH ENTRY</span>
                    </div>

                   <div className='option'>
                        <span className="wow fadeInUp"  data-wow-duration="2s"><i className="fa fa-check-circle pop-icons"></i> FREE SURVEY</span>
                        <span className="wow fadeInUp"  data-wow-duration="2s"><i className="fa fa-check-circle pop-icons"></i> DEED OF CONVEYANCE</span>
                    </div>
                    
                </div>

             </section>


            <section className='estateSecTwo'>




        {estate && estate.length > 0
                    ? estate.map(e => {
                        return <>

                      
              <div className='estate'>
                    <div className='boxImg'>
                        <LazyLoadImage src={`${configData.PIC_URL}/${e.image}`} className="d-inline-block align-top img-fluid est-img wow slideInLeft" data-wow-duration="1s" alt="" effect="blur"/>  
                    </div>
                    <div className='boxContent wow slideInRight' data-wow-duration="1.4s">
                                    {e.isSoldOut === true && <>
                                    <span className="soldOut" href="#"> (Sold Out)</span></>}
                           <div className='titleDiv'>
                                <div className=''>
                                    <span>{e.location}</span>
                                    <h4 className='estName'>{e.name}</h4>
                                </div>

                                <NumberFormat value={e.price} displayType={'text'} thousandSeparator={true} prefix={'₦ '} renderText={text => <h4 className='estPrice'>{text}</h4>} />
                            </div>
                            {e.pageContent.substring(0, 280).split('<>').map(page => (
                                <span>{page}</span>
                            ))}<span>...</span>
                              <div className="row w-100">

                              {e.features.split(',').map(feat => (
                                 <div className="col-md-6 mt-2">
                                    <i class="fa fa-check-circle pop-icons"></i>
                                    <span>{feat}</span>
                                    </div>
                                        ))}

                                

                              </div>
                              <div className='viewMore'>
                                <span onClick={() => estDetails(e)} >View More</span>
                              </div>

                        </div>
                </div>

                


                    </> })
                    :<section className="container mt-5">
                    <div className="row justify-content-center align-items-center">
                            <p style={{color:'#000', fontWeight:"bold", marginTop:'20px', fontSize:'20px', alignSelf:"center"}}>No Estate Found</p>
                        </div>
                     </section>}






            </section>



</>)} 



            
        </Layout>
    );
};

export default Estates;