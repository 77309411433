import React from 'react';
import { Link } from "react-router-dom";
 
const Footer = () => {

  const watsApp = () => {
    window.location.href = "https://wa.me/+2347040005553";
  }


    return (
        <>
        
        
<footer className="page-footer font-small unique-color-dark">



<div className="seconeFooter text-md-left mt-5">

  
  <div className="row mt-3">

    
    <div className="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">

      <h6 className="text-uppercase font-weight-bold">Entero Homes Limited</h6>
      <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
      <p>No. 3, King Maxwell Olowu Close, Ezingbu, Port Harcourt, Rivers State.</p>

    </div>
    
    <div className="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">

      <h6 className="text-uppercase font-weight-bold">Usefull Links</h6>
      <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
      <li className="listP">
        <Link to="/About-Us">About</Link>
      </li>
      <li className="listP">
        <Link to="/Estates-Layout">Estates/Layouts</Link>
      </li>
      <li className="listP">
        <Link to="/Services">Services</Link>
      </li>
    </div>
    

    
    <div className="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">

      <h6 className="text-uppercase font-weight-bold">Get in Touch</h6>
      <hr className="deep-purple accent-2 mb-4 mt-0 d-inline-block mx-auto" style={{width: "60px"}}/>
      <li className="listP">
        <Link href="mailto:info@enterohomes.com"> <i className="fa fa-envelope mr-3"></i> info@enterohomes.com</Link>
      </li>
      <li className="listP">
        <Link href="https://wa.me/+2347040005553" className="waves-effect waves-light">
        <i className="fa fa-phone mr-3"></i> +2347040005553</Link>
      </li>

    </div>
    

    
    <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">

      
      <h6 className="text-uppercase font-weight-bold">become a realtor</h6>

    </div>
    

  </div>
  

</div>

<div className="footer-copyright text-center py-3">
  <small>Copyright© 2023 ENTERO HOMES LIMITED. All rights reserved.</small>
</div>

   <span onClick={watsApp} className="whatsApp wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
      
              
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/>
                    </svg>
                    <span>Contact us</span>
                  
   </span>


             <div className="social wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.2s">
               <ul>
                 <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.5s">
                   <a href="https://www.facebook.com/enterohomes" className='square'>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                    </svg>
                   </a>
                 </li>
                 <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.2s">
                   <a href="https://www.instagram.com/enterohomes">
                   <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram"><title>Instagram</title><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                   </a>
                 </li>
                 <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                   <a href='mailto:info@enterohomes.com'>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                   </a>
                 </li>
                 {/* <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.5s">
                   <a href="https://www..com/">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M380.9 97.1C339 55.1 283.2 32 223.9 32c-122.4 0-222 99.6-222 222 0 39.1 10.2 77.3 29.6 111L0 480l117.7-30.9c32.4 17.7 68.9 27 106.1 27h.1c122.3 0 224.1-99.6 224.1-222 0-59.3-25.2-115-67.1-157zm-157 341.6c-33.2 0-65.7-8.9-94-25.7l-6.7-4-69.8 18.3L72 359.2l-4.4-7c-18.5-29.4-28.2-63.3-28.2-98.2 0-101.7 82.8-184.5 184.6-184.5 49.3 0 95.6 19.2 130.4 54.1 34.8 34.9 56.2 81.2 56.1 130.5 0 101.8-84.9 184.6-186.6 184.6zm101.2-138.2c-5.5-2.8-32.8-16.2-37.9-18-5.1-1.9-8.8-2.8-12.5 2.8-3.7 5.6-14.3 18-17.6 21.8-3.2 3.7-6.5 4.2-12 1.4-32.6-16.3-54-29.1-75.5-66-5.7-9.8 5.7-9.1 16.3-30.3 1.8-3.7.9-6.9-.5-9.7-1.4-2.8-12.5-30.1-17.1-41.2-4.5-10.8-9.1-9.3-12.5-9.5-3.2-.2-6.9-.2-10.6-.2-3.7 0-9.7 1.4-14.8 6.9-5.1 5.6-19.4 19-19.4 46.3 0 27.3 19.9 53.7 22.6 57.4 2.8 3.7 39.1 59.7 94.8 83.8 35.2 15.2 49 16.5 66.6 13.9 10.7-1.6 32.8-13.4 37.4-26.4 4.6-13 4.6-24.1 3.2-26.4-1.3-2.5-5-3.9-10.5-6.6z"/></svg>
                   </a>
                 </li> */}
                
                 <li>
                   <span className="line"> </span>
                 </li>
               </ul>

             </div> 


</footer>
  
        
        
        
        
        </>
    );
};

export default Footer;