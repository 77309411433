import React from 'react';

import { Link } from "react-router-dom";
import Layout from '../Layout';

const AboutUs = () => {
    return (

        <Layout>


            <section className="abtSecOne">
                <div className="subOne">
                    <h3 className='wow fadeInUp' data-wow-duration="0.8s">Real Estate </h3>
                        <h3 className='posOne wow fadeInUp' data-wow-duration="1s">with exceptional</h3>
                        <h3 className='posTwo wow fadeInUp' data-wow-duration="1.2s"> features.</h3>
                </div>
                <div className="subTwo">

                        <div className='highlightSec'>
                                    <div className='mini'>
                                        <h5 className="wow fadeInUp"  data-wow-duration="0.8s">10k +</h5>
                                        <p className="wow fadeInUp"  data-wow-duration="1s">Happy Clients</p>
                                    </div>
                                    <div className='mini'>
                                        <h5 className="wow fadeInUp"  data-wow-duration="1.2s">1.5k +</h5>
                                        <p className="wow fadeInUp"  data-wow-duration="1.4s">Properties Sold</p>
                                    </div>
                                    <div className='mini'>
                                        <h5 className="wow fadeInUp"  data-wow-duration="1.6s">10 +</h5>
                                        <p className="wow fadeInUp"  data-wow-duration="1.8s">Estates/Layouts</p>
                                    </div>
                            </div>
                        <div>
                           <p className="paragraph-title wow fadeInUp"  data-wow-duration="1.2s">We are a fast growing real estate company rapidly spreading its frontiers across Nigeria, with sole interest in sales/purchase of properties, construction, human capital development and consultancy.</p>
                        </div>
                    
                </div>

            </section>


            <section className="abtSecTwo">

                <div className='secTwoDivOne'>
                    <span></span>
                </div>
                <div className='secTwoDivTwo'>
                    <div className='imgDiv'>
                       <img src="images/abtBg.jpg" className="d-inline-block align-top img-fluid wow fadeInUp"  loading="lazy"  data-wow-duration="1.2s" alt=""/>  
                    </div>
                    <div className='imgDivTwo'>
                        <div className='one'>
                           <h4 className="wow fadeInUp"  data-wow-duration="1.2s">We are offering you more to choose from. With us, investment is stress free and hassle free with</h4>
                        </div>
                        <div className="two wow fadeInUp"  data-wow-duration="1.2s">
                          <img src="images/imgPlay.jpg" className="d-inline-block align-top img-fluid wow fadeInUp playImg" data-wow-duration="1.2s" alt=""/>  
                        </div>
                    </div>
                </div>


            </section>


            <section className="abtSecThree">

                <div className='vision'>
                    <div className='vmContent mb-4'>
                        <h3 className="wow fadeInUp"  data-wow-duration="1s">Our Vision</h3>
                        <p className="wow fadeInUp"  data-wow-duration="1.2s">To become Africa’s leading real estate firm through providing services for client regardless of their socio-economic status and creating investment in the real estate industry.</p>
                    </div>
                    <img src="images/vision.png" className="d-inline-block align-top mvImg img-fluid wow fadeInUp"  data-wow-duration="1.2s" alt=""/>  

                </div>

                <div className='mission'>
                    <img src="images/mission.jpg" className="d-inline-block align-top mvImg img-fluid wow fadeInUp"  data-wow-duration="1s" alt=""/>
                    <div className='vmContent mt-4'>
                        <h3 className="wow fadeInUp"  data-wow-duration="1.2s">Our mission</h3>
                        <p className="wow fadeInUp"  data-wow-duration="1.2s">To be an exceptional real estate company that provides professional services to clients.</p>
                    </div>

                </div>


            </section>


            <section className="abtSecFour">

                <div className='sixReasonsWords'>
                    <div className='vmContent'>
                        <h3 className='wow fadeInUp' data-wow-duration="0.5s">6 Reasons You Should Buy From</h3>
                        <h3 className='wow fadeInUp' data-wow-duration="0.7s">ENTERO HOMES</h3>
                    </div>

                </div>

                <div className='sixReasonsImg'>

                   <div className='stepsAbtCon mt-5'>
                        
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                                <div>
                                  <span>1</span>
                                </div>
                                <h4> We are stable</h4>
                        </div>
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                               <div>
                                <span>2</span>
                               </div>
                                <h4> We have a huge client base.</h4>
                        </div>
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                                <div>
                                  <span>3</span>
                                </div>
                                <h4> Our properties are not on government area of interest</h4>
                        </div>

                        
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                               <div>
                                <span>4</span> 
                               </div>
                                <h4> Our lands are cleared and readily available.</h4>
                        </div>
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                                <div>
                                  <span>5</span>
                                </div>
                                <h4> Instant allocation once 75% 0r 100% of payment is done.</h4>
                        </div>
                        <div className='steps wow fadeInUp' data-wow-duration="0.8s">
                               <div>
                                <span>6</span>
                                </div>
                                <h4> All properties comes with a free Deed of Conveyance Document and survey plan.</h4>
                        </div>

                     </div>

                </div>



            </section>




            
        </Layout>

    );
};

export default AboutUs;