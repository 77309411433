import React, {useEffect} from 'react';
import { Link, useLocation } from "react-router-dom";
import WOW from 'wowjs';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';


const NavBar = () => {
    const location = useLocation();


  useEffect(() => {
    new WOW.WOW({
    live: false
        }).init();
    },[]);





    return (
        <>
    
   <div className='contain-nav'>

   <Navbar  expand="lg">
      <Container>
        <Navbar.Brand>

        <Link to="/"> 
          <img src="/img/logo.png" className="logo d-inline-block align-top img-fluid wow fadeInDown"  data-wow-duration="0.5s" alt=""/>
          </Link>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Link className={`${location.pathname === "/About-us" && "activeNav"}`}>
                <Link to="/About-us" className="nav-link waves-effect waves-light wow fadeInDown"  data-wow-duration="0.8s">About Us</Link>
            </Nav.Link>
            <Nav.Link className={`${location.pathname === "/Estates-Layout" && "activeNav"}`}>
                  <Link to="/Estates-Layout" className="nav-link waves-effect waves-light wow fadeInDown"  data-wow-duration="1.2s">Estates/Layouts</Link>
             </Nav.Link>
            <Nav.Link className={`${location.pathname === "/Services" && "activeNav"}`}>
                <Link to="/Services" className="nav-link waves-effect waves-light wow fadeInDown"  data-wow-duration="1.6s">Services</Link>
            </Nav.Link>
            <Nav.Link className={`${location.pathname === "/Events" && "activeNav"}`}>
                <Link to="/Events" className="nav-link waves-effect waves-light wow fadeInDown"  data-wow-duration="2s">Events</Link>
            </Nav.Link>
          </Nav>
          <Nav>
            <Nav.Link  className="mx-auto  link-rounded  wow fadeInDown"  data-wow-duration="2.2s">
                <Link to="/Interest-Form" className="nav-link waves-effect waves-light">Interested Client</Link>
            </Nav.Link>
            <Nav.Link  className="mx-auto wow fadeInDown"  data-wow-duration="2.2s">
                <div className="socialMD">

                <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.5s">
                   <a href="https://www.facebook.com/enterohomes" className='square'>
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-activity">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-facebook"><path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path></svg>
                    </svg>
                   </a>
                 </li>
                 <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="1.2s">
                   <a href="https://www.instagram.com/enterohomes">
                   <svg xmlns="http://www.w3.org/2000/svg" role="img" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="feather feather-instagram"><title>Instagram</title><rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect><path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path><line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line></svg>
                   </a>
                 </li>
                 <li className=" wow fadeInUp" data-wow-duration="1s" data-wow-delay="0.8s">
                   <a href="https://www.facebook.com/">
                   <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M64 112c-8.8 0-16 7.2-16 16v22.1L220.5 291.7c20.7 17 50.4 17 71.1 0L464 150.1V128c0-8.8-7.2-16-16-16H64zM48 212.2V384c0 8.8 7.2 16 16 16H448c8.8 0 16-7.2 16-16V212.2L322 328.8c-38.4 31.5-93.7 31.5-132 0L48 212.2zM0 128C0 92.7 28.7 64 64 64H448c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128z"/></svg>
                   </a>
                 </li>
                </div>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    



            </div>
            
        </>
    );
};

export default NavBar;